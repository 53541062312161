import React from "react";
import {
  Link,
  Routes,
  Route,
  useParams,
  useMatch,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { cx, css } from "@emotion/css";
import urlHelpers from "../lib/url-helpers";
import PublishTags from "./organizations/publish-tags.js";
import useIsSignedIn from "../hooks/use-is-signed-in";
import { DESKTOP, MOBILE } from "../lib/breakpoints";
import { ProductTypeEnum } from "../graphql-types";

const StellarLogo = () => {
  const size = "16px";
  return (
    <div
      className={cx(
        css`
          background-image: url("https://static-assets.stellartickets.com/branding/favicon.svg?v2");
          background-repeat: no-repeat;
          background-size: ${size} ${size};
          background-position: center center;
          display: inline-block;
          height: ${size};
          width: ${size};
        `,
        "mr-2"
      )}
    />
  );
};

const OrgToolbar = ({
  result,
  title,
  published,
  containerClassName = "container",
  dashboardLink,
  editLink,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // The intent here is to not display the org toolbar when the organization is 404ing.
  if (result && result.data && result.data.organization === null) return null;
  return (
    <div
      className={cx(
        "org-toolbar",
        css`
          background-color: var(--white);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          --black: #030f1c;
          --teal: #0c988b;
          --teal-darker: #07574e;
          color: var(--black);
          z-index: 31;
          padding-top: 2px;
          padding-bottom: 2px;
          position: relative;

          @media (max-width: ${DESKTOP}px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }

          @media (max-width: ${MOBILE}px) {
            padding-left: 1rem;
            padding-right: 1rem;
          }

          .org-toolbar-inner-container {
            align-items: center;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            position: relative;
          }

          .toolbar-link {
            border: 0;
            background: none;
            color: var(--teal);
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            padding-bottom: 5px;
            padding-top: 5px;
            font-size: 12px;
            font-weight: bold;
          }
          .toolbar-link:visited {
            color: var(--teal);
          }
          .toolbar-link:hover {
            color: var(--teal-darker);
          }

          .toolbar-center {
            display: flex;
            align-items: center;
          }

          .toolbar-label {
            font-weight: bold;
            overflow: hidden;
            max-width: 300px;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
          }

          @media (max-width: ${MOBILE}px) {
            .toolbar-label {
              display: none;
            }
          }
        `
      )}
    >
      <div className={cx(`org-toolbar-inner-container`, containerClassName)}>
        <div>
          <StellarDashboardLink dashboardLink={dashboardLink} />
        </div>
        <div className="toolbar-center">
          <span className="toolbar-label">{title}</span>
          {editLink}
          {typeof published === "boolean" && (
            <PublishTags
              className="ml-2"
              published={published}
              flippable={false}
            />
          )}
        </div>
        <div>
          <button
            className="toolbar-link mr-2"
            type="button"
            onClick={() => {
              navigate(
                {
                  pathname: location.pathname,
                  search: location.search, // forward ?preview=true along
                },
                {
                  state: {
                    orgThemeView:
                      location.state?.orgThemeView === "theme" ? null : "theme",
                  },
                  replace: true,
                }
              );
            }}
          >
            Theme
          </button>
          <button
            className="toolbar-link is-hidden-mobile"
            type="button"
            onClick={() => {
              navigate(
                {
                  pathname: location.pathname,
                  search: location.search, // forward ?preview=true along
                },
                {
                  state: {
                    orgThemeView:
                      location.state?.orgThemeView === "images"
                        ? null
                        : "images",
                  },
                  replace: true,
                }
              );
            }}
          >
            Images
          </button>
        </div>
      </div>
    </div>
  );
};

const PageToolbar = ({ links }) => {
  const homeSlug = "";
  const { orgSlug, pageSlug = homeSlug } = useParams();
  const result = useQuery(
    gql`
      query OrgToolbarPageQuery($orgSlug: String, $pageSlug: String) {
        organization(slug: $orgSlug) {
          id
          page(slug: $pageSlug) {
            id
            published
            title
          }
        }
      }
    `,
    { variables: { orgSlug, pageSlug } }
  );
  const page = result.data?.organization?.page;
  const pageLink = `${links.dashboard}/${orgSlug}/pages/${page?.id}`;
  const isHome = pageSlug === homeSlug;

  return (
    <OrgToolbar
      result={result}
      title={
        page
          ? isHome
            ? "Home Page"
            : `${page.title || "[Untitled]"} Page`
          : ""
      }
      published={page?.published}
      dashboardLink={`${links.dashboard}/${orgSlug}`}
      editLink={
        <Link className="toolbar-link pl-2" to={pageLink}>
          <span>Edit</span>
        </Link>
      }
    />
  );
};

const EventToolbar = ({ links }) => {
  const { orgSlug, eventSlug, occurrenceId } = useParams();
  const result = useQuery(
    gql`
      query OrgToolbarEventQuery($orgSlug: String, $eventSlug: String) {
        organization(slug: $orgSlug) {
          id
          event(slug: $eventSlug) {
            id
            name
            published
          }
        }
      }
    `,
    { variables: { orgSlug, eventSlug } }
  );
  const event = result.data?.organization.event;
  let link = `${links.dashboard}/${orgSlug}/events/${event?.id}`;
  if (occurrenceId) link += `/occurrences/` + occurrenceId;
  return (
    <OrgToolbar
      result={result}
      title={event?.name}
      published={event?.published}
      dashboardLink={`${links.dashboard}/${orgSlug}`}
      editLink={
        <Link className="toolbar-link pl-2" to={link}>
          <span>Edit</span>
        </Link>
      }
    />
  );
};

const StellarDashboardLink = ({ dashboardLink }) => {
  return (
    <Link className="toolbar-link mr-1" to={dashboardLink}>
      <StellarLogo />
      <span className="is-hidden-mobile">Dashboard</span>
    </Link>
  );
};

const GenericToolbar = ({ links, title }) => {
  const { orgSlug } = useParams();
  return (
    <OrgToolbar title={title} dashboardLink={`${links.dashboard}/${orgSlug}`} />
  );
};

const ProductToolbar = ({ links }) => {
  const { orgSlug, productId } = useParams();
  const result = useQuery(
    gql`
      query OrgToolbarProductQuery($orgSlug: String, $productId: ID!) {
        organization(slug: $orgSlug) {
          id
          product(productId: $productId) {
            id
            published
            typeEnum
            ... on SubscriptionProduct {
              name
            }
            ... on BundleProduct {
              name
            }
            ... on RentalProduct {
              name
            }
            ... on MerchandiseProduct {
              name
            }
            ... on DigitalMerchandiseProduct {
              name
            }
          }
        }
      }
    `,
    { variables: { orgSlug, productId } }
  );
  const product = result.data?.organization?.product;
  const dashboardLink = `${links.dashboard}/${orgSlug}`;

  if (!product) {
    return <GenericToolbar title="" links={links} />;
  }

  const link =
    dashboardLink +
    {
      [ProductTypeEnum.Merchandise]: links.merchProduct,
      [ProductTypeEnum.Subscription]: links.subscriptionProduct,
      [ProductTypeEnum.Bundle]: links.bundleProduct,
      [ProductTypeEnum.Rental]: links.rentalProduct,
      [ProductTypeEnum.DigitalMerchandise]: links.digitalMerchProduct,
    }[product.typeEnum]({ productId: product.id });

  return (
    <OrgToolbar
      result={result}
      title={product.name}
      published={product.published}
      dashboardLink={`${links.dashboard}/${orgSlug}`}
      editLink={
        <Link className="toolbar-link pl-2" to={link}>
          <span>Edit</span>
        </Link>
      }
    />
  );
};

const OrgToolbarRoutes = ({ links }) => {
  const isUserSignedIn = useIsSignedIn();
  const match = useMatch(urlHelpers.organization.home(":orgSlug", "*"));
  const orgSlug = match?.params.orgSlug;
  const shouldQueryCurrentUser = Boolean(orgSlug && isUserSignedIn);
  const userResult = useQuery(
    gql`
      query OrgToolbarUserCheck($orgSlug: ID) {
        currentUser {
          id
          staffMember {
            id
          }
          organizations(where: { slug: { equals: $orgSlug } }) {
            nodes {
              id
              name
              slug
            }
            totalCount
          }
        }
      }
    `,
    { variables: { orgSlug }, skip: !shouldQueryCurrentUser }
  );
  const currentUser = userResult.data?.currentUser;
  const doesUserBelongToOrg =
    currentUser?.organizations.totalCount > 0 ?? false;
  const isStaff = currentUser?.staffMember;
  const hasToolbar = doesUserBelongToOrg || isStaff;

  links = {
    ...links,
    subscriptionProduct: ({ productId }) => `/subscriptions/${productId}`,
    bundleProduct: ({ productId }) => `/bundles/${productId}`,
    rentalProduct: ({ productId }) => `/rentals/${productId}`,
    merchProduct: ({ productId }) => `/merch/${productId}`,
    digitalMerchProduct: ({ productId }) => `/digital-merch/${productId}`,
  };

  if (!hasToolbar) return null;

  return (
    <>
      <Routes>
        <Route
          path={urlHelpers.organization.occurrences(
            ":orgSlug",
            ":eventSlug",
            ":occurrenceId"
          )}
          element={<EventToolbar links={links} />}
        />
        <Route
          end
          path={urlHelpers.organization.events(":orgSlug", ":eventSlug")}
          element={<EventToolbar links={links} />}
        />
        <Route
          path={urlHelpers.organization.subscriptions(
            ":orgSlug",
            ":productId",
            "*"
          )}
          element={<ProductToolbar links={links} />}
        />
        <Route
          path={urlHelpers.organization.bundles(":orgSlug", ":productId", "*")}
          element={<ProductToolbar links={links} />}
        />
        <Route
          path={urlHelpers.organization.rentals(":orgSlug", ":productId", "*")}
          element={<ProductToolbar links={links} />}
        />
        <Route
          path={urlHelpers.organization.merch(":orgSlug", ":productId", "*")}
          element={<ProductToolbar links={links} />}
        />
        <Route
          path={urlHelpers.organization.digitalMerch(
            ":orgSlug",
            ":productId",
            "*"
          )}
          element={<ProductToolbar links={links} />}
        />
        <Route
          path={urlHelpers.organization.redeem(":orgSlug", "*")}
          element={<GenericToolbar title="Redeem" links={links} />}
        />
        <Route
          path={urlHelpers.organization.cart(":orgSlug")}
          element={<GenericToolbar title="Checkout" links={links} />}
        />
        <Route
          path={urlHelpers.organization.checkout(":orgSlug", ":cartId")}
          element={<GenericToolbar title="Checkout" links={links} />}
        />
        <Route
          path={urlHelpers.organization.subscribe(":orgSlug")}
          element={<GenericToolbar title="Subscribe" links={links} />}
        />
        <Route
          path={urlHelpers.organization.page(":orgSlug", ":pageSlug")}
          element={<PageToolbar links={links} />}
        />
        <Route
          path={urlHelpers.organization.search(":orgSlug")}
          element={<GenericToolbar links={links} />}
        />
        <Route
          end
          path={urlHelpers.organization.home(":orgSlug")}
          element={<PageToolbar links={links} />}
        />
      </Routes>
    </>
  );
};

export default OrgToolbarRoutes;
