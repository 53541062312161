import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAnalytics, setCurrentScreen } from "firebase/analytics";

import { trackStellarEvent } from "../lib/analytics-tracking.js";
import { app } from "../lib/firebase";

const Pageviews = () => {
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics(app);
    setCurrentScreen(analytics, window.location.pathname);
    trackStellarEvent({ event: "screen_view" });
  }, [location]);

  return null;
};

export default Pageviews;
