import React, { Suspense, lazy } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { isDevelopment } from "../environment";
const LazyZendeskChatButton = lazy(() => import("./zendesk-chat-button.js"));

const ZendeskChatRoutes = () => {
  const { pathname } = useLocation();
  const zendeskRoutes = [
    "/dashboard/*",
    "/wallet/*",
    "/wallet/:walletItem",
    "/log-in",
    "/auth/action",
    "/pages/help",
  ].some((path) => matchPath(path, pathname));

  if (isDevelopment || !zendeskRoutes) return null;

  const isStreamStudio = matchPath(
    "/dashboard/:orgSlug/stream-studio-beta/*",
    pathname
  );

  return (
    <Suspense fallback={null}>
      <LazyZendeskChatButton isSmall={isStreamStudio} />
    </Suspense>
  );
};

export default ZendeskChatRoutes;
