performance.mark("authenticated-app-routes.js top");
import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { gql } from "@apollo/client";

import { isProduction } from "./environment";
import BulmaStylesheets from "./components/bulma-stylesheets.js";
import HeaderRoutes from "./components/header-routes.js";
import SignOut from "./components/auth/signout.js";
import FirebaseAuthReady from "./components/auth/firebase-auth-ready";
import ProtectedRoute from "./components/protected-route.js";
import ErrorBoundary from "./components/error";
import PageErrorBoundary from "./components/page-error-boundary.js";
import Pageviews from "./components/pageviews.js";
import useSyncFirebaseUser from "./hooks/use-sync-firebase-user.js";
import useSyncGTMUser from "./hooks/use-sync-gtm-user.js";
import ZendeskChatRoutes from "./components/zendesk-chat-routes.js";
import OrganizationRoutes, {
  PreviewHandler,
} from "./components/organization-routes.js";
import OrgToolbarRoutes from "./components/org-toolbar-routes.js";
import useCurrentUser from "./hooks/use-current-user";
import renderApplication, { GlobalLinks } from "./render-application";
import isSignedInVar from "./apollo-client-vars/is-signed-in-var.js";
import {
  registerFirebaseCallbacks,
  getFirebaseAuthorizationLink,
} from "./lib/firebase";
import urlHelpers from "./lib/url-helpers";
import useLanguageChangeNotification from "./hooks/use-language-change-notification.js";
import { Scalars } from "./graphql-types";

type ID = Scalars["ID"];

const LazyHomeLandingPage = lazy(() => import("./components/home.js"));
const LazyEventsIndex = lazy(() => import("./components/events-index.js"));
const LazySearchPage = lazy(() => import("./components/search-page.js"));
const LazyLogInPage = lazy(() => import("./components/auth/log-in-page.js"));
const LazyOrganizations = lazy(() => import("./components/organizations.js"));
const LazyOrgDashboardRedirect = lazy(
  () => import("./components/org-dashboard-redirect.js")
);
const LazyOrgDashboardRoutes = lazy(
  () => import("./components/org-dashboard-routes.js")
);
const LazyPromoters = lazy(() => import("./components/promoters.js"));
const LazyTicketTransfer = lazy(
  () => import("./components/ticket-transfer.js")
);
const LazyWallet = lazy(() => import("./components/wallet.js"));
const LazyAccountSettingsRoutes = lazy(
  () => import("./components/settings/routes.js")
);
const LazyStream = lazy(() => import("./components/stream.js"));
const LazyVODStream = lazy(() => import("./components/vod-stream.js"));
const LazyRentalStream = lazy(() => import("./components/rental-stream"));
const LazyPage = lazy(() => import("./components/cms/pages/index.js"));
const LazyOrganizationInvite = lazy(
  () => import("./components/organization-invite.js")
);
const LazyConfirmResetPassword = lazy(
  () => import("./components/confirm-reset-password.js")
);
const LazyBadLinkStyles = lazy(() => import("./components/bad-link-styles.js"));
const LazyPopoutChat = lazy(() => import("./components/chat/popout-chat.js"));
const LazySetXCartToken = lazy(
  () => import("./components/set-x-cart-token.js")
);
const LazyActivate = lazy(() => import("./components/activate.js"));
const LazyOauth = lazy(() => import("./components/oauth.js"));

const LazyCurrentStreams = lazy(
  () => import("./components/current-streams.js")
);

const useSetUserForSentry = () => {
  const { data } = useCurrentUser();
  const id = data?.currentUser?.id;
  // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
  useEffect(() => {
    if (id) {
      Sentry.setUser({ id });
    } else {
      return () => {
        Sentry.configureScope((scope) => scope.setUser(null));
      };
    }
  }, [id]);
};

const useCleanUpReact18InLocalStorage = () => {
  const currentUserResult = useCurrentUser();
  const isStaff = Boolean(currentUserResult.data?.currentUser?.staffMember);
  useEffect(() => {
    if (isStaff) {
      try {
        const value = localStorage.getItem("ENABLE_REACT_18");
        const isExplicitlyDisabled = value && value !== "true";
        if (!isExplicitlyDisabled) {
          localStorage.removeItem("ENABLE_REACT_18");
        }
      } catch (e) {
        // no-op
      }
    }
  }, [isStaff]);
};

const AuthenticatedAppRoutes = ({
  links: externalLinks,
}: {
  links: GlobalLinks;
}) => {
  useSyncFirebaseUser();
  useSyncGTMUser();
  useSetUserForSentry();
  useCleanUpReact18InLocalStorage();
  useLanguageChangeNotification();

  const links = {
    ...externalLinks,
    dashboard: "/dashboard",
    search: "/search",
    eventsIndex: "/events",
    addOrganization: "/organizations/new",
    wallet: "/wallet",
    walletItem: ({ occurrenceId }: { occurrenceId: ID }) =>
      `/wallet/${occurrenceId}`,
    streamPage: ({ occurrenceId }: { occurrenceId: ID }) =>
      `/streams/${occurrenceId}`,
    rentalStream: ({ rentalId }: { rentalId: ID }) => `/rentals/${rentalId}`,
    purchases: "/settings/purchases",
    subscriptions: "/settings/subscriptions",
    devices: "/settings/devices",
    language: "/settings/language",
    subscription: ({ subscriptionId }: { subscriptionId: ID }) =>
      `/settings/subscriptions/${subscriptionId}`,
    settings: "/settings",
    logOut: "/log-out",
    orgWallet: ({ orgSlug }: { orgSlug: string }) => {
      return `/o/${orgSlug}/wallet`;
    },
    orgWalletItem: ({
      orgSlug,
      occurrenceId,
    }: {
      orgSlug: string;
      occurrenceId: ID;
    }) => `/o/${orgSlug}/wallet/${occurrenceId}`,
    orgWalletSubscription: ({
      orgSlug,
      subscriptionId,
    }: {
      orgSlug: string;
      subscriptionId: ID;
    }) => {
      return `/o/${orgSlug}/wallet/subscriptions/${subscriptionId}`;
    },
    help: "/pages/help",
  };

  return (
    <>
      <Pageviews />
      <BulmaStylesheets />
      <Routes>
        <Route path="/log-out" element={<SignOut />} />
        <Route
          path="*"
          element={
            <>
              <HeaderRoutes links={links} />
              <ZendeskChatRoutes />
              <Suspense fallback={null}>
                {!isProduction && <LazyBadLinkStyles />}
                <Routes>
                  <Route
                    path="/log-in"
                    element={
                      <FirebaseAuthReady>
                        <PageErrorBoundary key="login">
                          <LazyLogInPage />
                        </PageErrorBoundary>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/auth/action"
                    element={
                      <FirebaseAuthReady>
                        <PageErrorBoundary key="firebase-auth-action">
                          <LazyConfirmResetPassword />
                        </PageErrorBoundary>
                      </FirebaseAuthReady>
                    }
                  />
                </Routes>
                <FirebaseAuthReady>
                  <ErrorBoundary fallback={null}>
                    <OrgToolbarRoutes links={links} />
                  </ErrorBoundary>
                </FirebaseAuthReady>
                <Routes>
                  <Route
                    path="/ticket-transfer/:ticketTransferId/complete"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="ticket-transfer">
                            <LazyTicketTransfer links={links} />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/wallet/*"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="wallet">
                            <LazyWallet links={links} />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/settings/*"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="account-settings">
                            <LazyAccountSettingsRoutes links={links} />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <LazyOrgDashboardRedirect links={links} />
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/dashboard/:orgSlug/*"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <LazyOrgDashboardRoutes links={links} />
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/organizations/*"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <LazyOrganizations links={links} />
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/activate"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="activate">
                            <LazyActivate />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/promoters/:promoterOrgId"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <LazyPromoters links={links} />
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/streams/:occurrenceId"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="stream">
                            <LazyStream />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/chat/:occurrenceId"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="chat">
                            <LazyPopoutChat />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/vods/:vodId"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="vod">
                            <LazyVODStream links={links} />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/rentals/:rentalId"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="rentals">
                            <LazyRentalStream links={links} />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/organization-invite/:inviteId"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <LazyOrganizationInvite />
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/oauth/authorize"
                    element={
                      <FirebaseAuthReady>
                        <ProtectedRoute>
                          <PageErrorBoundary key="oauth">
                            <LazyOauth />
                          </PageErrorBoundary>
                        </ProtectedRoute>
                      </FirebaseAuthReady>
                    }
                  />
                  <Route
                    path="/search"
                    element={
                      <PageErrorBoundary key="search">
                        <LazySearchPage links={links} />
                      </PageErrorBoundary>
                    }
                  />
                  <Route
                    path="/events/*"
                    element={
                      <PageErrorBoundary key="events">
                        <LazyEventsIndex />
                      </PageErrorBoundary>
                    }
                  />
                  <Route
                    path={urlHelpers.organization.home(":orgSlug", "*")}
                    element={
                      <PageErrorBoundary key="org-home">
                        <PreviewHandler>
                          <OrganizationRoutes links={links} />
                        </PreviewHandler>
                      </PageErrorBoundary>
                    }
                  />
                  <Route
                    path="/pages/:slug"
                    element={
                      <PageErrorBoundary key="graphcms-page">
                        <LazyPage />
                      </PageErrorBoundary>
                    }
                  />
                  <Route
                    path="/set-x-cart-token/:token"
                    element={<LazySetXCartToken />}
                  />
                  <Route
                    path="/current-streams"
                    element={
                      <ProtectedRoute>
                        <PageErrorBoundary key="current-streams-page">
                          <LazyCurrentStreams links={links} />
                        </PageErrorBoundary>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <PageErrorBoundary key="home">
                        <LazyHomeLandingPage links={links} />
                      </PageErrorBoundary>
                    }
                  />
                </Routes>
              </Suspense>
            </>
          }
        />
      </Routes>
    </>
  );
};

performance.mark("renderApplication() start");
renderApplication({
  RoutesComponent: AuthenticatedAppRoutes,
  onApolloClientInit: ({ client }) => {
    registerFirebaseCallbacks({
      onSignIn: () => {
        isSignedInVar(true);
      },
      onSignOut: () => {
        isSignedInVar(false);
        // most side-effects are handled in components/auth/signout.js
      },
      onFirstAuthChange: () => {
        client.writeQuery({
          query: gql`
            query OnFirstAuthChangeSetIsFirebaseAuthStatusKnown {
              isFirebaseAuthStatusKnown @client
            }
          `,
          data: { isFirebaseAuthStatusKnown: true },
        });
      },
    });
  },
  extraApolloClientLinks: [getFirebaseAuthorizationLink],
});
performance.mark("renderApplication() end");
