import { useLayoutEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import useMatchMedia from "../hooks/use-match-media.js";

const usePrefersDarkMode = () => {
  return useMatchMedia(`(prefers-color-scheme: dark)`);
};

const findStylesheet = (filenameMatch) => {
  if (!filenameMatch) return null;
  const sheet = [...document.styleSheets].find((stylesheet) => {
    return stylesheet.href === filenameMatch;
  });
  return sheet;
};

const getHref = (id) => {
  return document.getElementById(id)?.href;
};

const consumer = findStylesheet(getHref("consumer_stylesheet"));
const org = findStylesheet(getHref("organizations_stylesheet"));
const orgDark = findStylesheet(getHref("organizations_dark_stylesheet"));

const useControlledStylesheet = (sheet, isActive) => {
  useLayoutEffect(() => {
    if (!sheet) return;
    sheet.disabled = !isActive;
    return () => {
      sheet.disabled = true;
    };
  }, [sheet, isActive]);
};

const BulmaStylesheets = () => {
  const { pathname } = useLocation();
  const streamStudioRouteMatch = [
    "/dashboard/:orgSlug/stream-studio",
    "/dashboard/:orgSlug/stream-studio/*",
  ].some((path) => matchPath(path, pathname));
  const orgRouteMatch =
    !streamStudioRouteMatch &&
    ["/organizations", "/organizations/*", "/dashboard", "/dashboard/*"].some(
      (path) => matchPath(path, pathname)
    );
  const wantsDark = usePrefersDarkMode();
  const promoterRouteMatch = matchPath("/promoters", pathname);
  const hasConsumerStylesheet =
    !streamStudioRouteMatch && !orgRouteMatch && !promoterRouteMatch;
  const hasLightOrgDashboardStylesheet =
    !hasConsumerStylesheet &&
    (orgRouteMatch || (streamStudioRouteMatch && !wantsDark));
  const hasDarkOrgDashboardStylesheet = streamStudioRouteMatch && wantsDark;
  useControlledStylesheet(consumer, hasConsumerStylesheet);
  useControlledStylesheet(org, hasLightOrgDashboardStylesheet);
  useControlledStylesheet(orgDark, hasDarkOrgDashboardStylesheet);
  return null;
};

export default BulmaStylesheets;
