import { useEffect } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import { app } from "../../lib/firebase";
import useSearchParams from "../use-search-params";
import { removeCartToken } from "../../lib/cart-token.js";
import { clearLastVisitedOrgSlug } from "../../hooks/use-last-visited-organization-slug.js";
import { seedInitialCacheState } from "../initialize-apollo-client.js";

// This component is mounted before all other Routes, to prevent any active
// GraphQL queries. This allows client.resetStore() to wipe the cache without
// breaking any existing queries, because there are none.
const SignOut = () => {
  const { searchParams } = useSearchParams();
  const returnTo = searchParams.get("returnTo") || "/";
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    const auth = getAuth(app);
    auth.signOut().then(() => {
      removeCartToken();
      clearLastVisitedOrgSlug();
      return client.resetStore().then(() => {
        seedInitialCacheState(client, {
          isFirebaseAuthStatusKnown: true,
        });
        navigate(returnTo, { replace: true });
      });
    });
  }, [returnTo, navigate, client]);
  return null;
};

export default SignOut;
