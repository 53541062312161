import React from "react";
import { matchPath, useLocation } from "react-router-dom";

import Header from "./head";
import ErrorBoundary from "./error";

const HeaderRoutes = ({ links }) => {
  const { pathname } = useLocation();
  const excluded = [
    "/organizations/*",
    "/dashboard/*",
    "/rentals/:id",
    "/vods/:id",
    "/streams/:id",
    "/chat/:id",
    "/o/:orgSlug/*",
    "/events/:orgSlug/promoter-signup/:affiliateProgramId",
    "/promoters",
    "/oauth/authorize",
  ].some((path) => matchPath(path, pathname));

  if (excluded) return null;
  return (
    <ErrorBoundary fallback={null}>
      <Header links={links} />
    </ErrorBoundary>
  );
};

export default HeaderRoutes;
