import { useEffect } from "react";
import { getAuth } from "firebase/auth";

import { app } from "../lib/firebase";
import useCurrentUser from "./use-current-user";
import useOnce from "./use-once.js";
import urlBearerToken from "../lib/device-token";

const trackLogin = (user, createdAt) => {
  dataLayer.push({
    event: "login",
    user: {
      email: user.email,
      id: user.id,
      name: user.name,
      createdAt: createdAt.substring(0, 10),
    },
  });
};

const useSyncGTMUser = () => {
  const { data } = useCurrentUser();
  const fireLoginEvent = useOnce(trackLogin);

  useEffect(() => {
    if (urlBearerToken) return;
    (async () => {
      const currentUser = data?.currentUser;
      const auth = getAuth(app);
      const firebaseCurrentUser = auth.currentUser;
      if (currentUser && firebaseCurrentUser) {
        const hasMetadata = firebaseCurrentUser.metadata.createdAt;
        if (!hasMetadata) {
          // When this runs after logging in, metadata will have empty values.
          // Reloading will fill in the values.
          try {
            await firebaseCurrentUser.reload();
          } catch (e) {
            // noop
          }
        }
        const createdAt = auth.currentUser.metadata.createdAt;
        if (createdAt) {
          fireLoginEvent(currentUser, createdAt);
        }
      }
    })();
  }, [data, fireLoginEvent]);
};

export default useSyncGTMUser;
