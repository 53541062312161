import { useEffect } from "react";
import { getAnalytics, setUserProperties } from "firebase/analytics";

import { app } from "../lib/firebase";
import useCurrentUser from "./use-current-user";

let sent = false;

const useSyncFirebaseUser = () => {
  const { data } = useCurrentUser();

  useEffect(() => {
    if (!data) return;

    const { currentUser } = data;

    if (currentUser?.staffMember?.id && !sent) {
      const analytics = getAnalytics(app);
      setUserProperties(analytics, { roles: "staff" });
      sent = true;
    }
  }, [data]);
};

export default useSyncFirebaseUser;
